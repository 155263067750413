import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Tabs from '../components/Tabs'
import 'react-medium-image-zoom/dist/styles.css'

const Experian = props => (
  <Layout>
    <Helmet>
      <title>Experian</title>
      <meta name="description" content="Experian" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <span
            style={{ display: 'flex', justifyContent: 'center' }}
            className="image main"
          >
            <img
              style={{ width: '30vh' }}
              src="https://upload.wikimedia.org/wikipedia/commons/3/31/Experian_logo.svg"
              alt="Experian"
            />
          </span>

          <section class="overview">
            <p>
              As my role as a UX designer, I worked on two main projects -
              Experian Boost and Fraud Alerts.
            </p>
            <p>
              Experian Boost is a service offered by Experian, one of the major
              credit reporting agencies in the United States, that allows
              individuals to potentially increase their credit scores by adding
              positive payment history from utility bills and other recurring
              payments to their credit reports. By linking their bank accounts
              and granting access to payment data, Experian analyzes eligible
              payments and incorporates them into the credit file.
            </p>
            <p>
              Fraud alerts are security measures offered by Experian. A fraud
              alert is a security measure that helps protect your credit
              information from unauthorized access or fraudulent activity. When
              you place a fraud alert with Experian, they notify lenders and
              creditors that they should take extra precautions to verify your
              identity before approving any credit applications.
            </p>

            <p>More information about these projects upon inquiry.</p>

            <div
              className="col-12"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '5%',
              }}
            ></div>
          </section>
        </div>
      </section>
    </div>
    <Tabs />
  </Layout>
)

export default Experian
